import React from 'react';
import { Grid } from '@material-ui/core';
import { Link } from 'gatsby'
import "animate.css/animate.min.css"
import ScrollAnimation from 'react-animate-on-scroll'
import MBDStyle from './mobandDescription.module.css'
import wow from '../assets/wow.jpg'
import multi from '../assets/multi.gif'
import roll from '../assets/roll.gif'
import pitch from '../assets/pitch.gif'
import yaw from '../assets/yaw.gif'
import trigger from '../assets/trigger.gif'
import mapping from '../assets/mapping.jpg'
import connect from '../assets/connect.jpg'

const MOBandDescription = () => {
    return (
        <React.Fragment>
            <div className={MBDStyle.detailWrapper}>
                <div className={MBDStyle.detail}>
                <h3 style={{textAlign:"center", fontSize: "30px", textTransform: "uppercase", fontWeight:700, marginBottom: '32px'}}>How it works</h3>
                <br/>
                <div className={MBDStyle.howItWorks}>
                    <Grid container spacing={10} style={{textAlign:"center"}}>
                    <Grid item sm>
                        <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                        <img className={MBDStyle.thumbnail} src={connect} alt="mo-key"></img>
                        <h4 className={MBDStyle.thumbnailHeading}>Connect to Computer</h4>
                        <p style={{fontWeight: 300, fontSize: '14px'}}>
                            Plug MO-Key (Receiver) in your computer (PC/MAC). It transfers the motion data to our <Link to="s-motion">S-Motion</Link> software, so that your physical hand movements can be used as digital control of your music.
                        </p>
                        </ScrollAnimation>
                    </Grid>
                    <Grid item sm>
                        <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                        <img className={MBDStyle.thumbnail} src={mapping} alt="mapping"></img>
                        <h4 className={MBDStyle.thumbnailHeading}>Customize Mapping</h4>
                        <p style={{fontWeight: 300, fontSize: '14px'}}>
                            Use S-Motion software to change the MIDI mappings such as channel number and CC number. Then choose what effects you'd like to control on your favourite DAW, everything is customizable.
                        </p>
                        </ScrollAnimation>
                    </Grid>
                    <Grid item sm>
                        <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                        <img className={MBDStyle.thumbnail} src={wow} alt="good-to-go"></img>
                        <h4 className={MBDStyle.thumbnailHeading}>Wow!</h4>
                        <p style={{fontWeight: 300, fontSize: '14px'}}>
                            Get ready to experience a new dimension of expressiveness which never exsited before.
                        </p>
                        </ScrollAnimation>
                    </Grid>
                    </Grid>
                </div>
                </div>
                </div>
            <div className={MBDStyle.parallax}>
                <div className={MBDStyle.benefitOneWrapper}>
                <div className={MBDStyle.benefitOne} style={{color:'white'}}>
                    <ScrollAnimation animateIn='fadeInLeft' animateOut='fadeOutLeft' duration={0.5} offset={200}>
                    <h3 style={{fontSize: "30px", textTransform: "uppercase", fontWeight:700}}>Wireless, Yet No Latency</h3>
                    <div className={MBDStyle.benefitText} style={{width: "40%", fontWeight: 300}}>
                        <p>
                            We know how critical it is when it comes to live performance. Any latency will lead to disaster. That's why we developed our own wireless protocol to eliminate it. This protocol has 10x lower latency than Bluetooth LE, yet MO-Band has a range of up to 500 feet. You will not feel any lag no matter where you go, just as good as plug in a cable.
                        </p>
                    </div>
                    </ScrollAnimation>
                </div>
                </div>
            </div>

            <div className={MBDStyle.detailWrapper}>
                <div className={MBDStyle.detail}>
                <h3 style={{fontSize: "30px", textTransform: "uppercase", fontWeight:700, textAlign: "center"}}>Multiple control at same time</h3>
                <p style={{textAlign: "center", fontWeight: 300, fontSize: '20px'}}>
                    Control with 3 dimensional movements. Use them seperately or combine them together, all up to you.
                </p>
                <br/>
                <div className={MBDStyle.howItWorks}>
                    <Grid container spacing={3} style={{textAlign:"center"}} alignContent='center' justify='center'>
                    <Grid item sm>
                        <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                        <img className={MBDStyle.thumbnail} src={roll} alt="roll"></img>
                        <h4 style={{letterSpacing: '0px', marginTop: '2rem'}}>Rolling</h4>
                        </ScrollAnimation>
                    </Grid>
                    <Grid item sm>
                        <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                        <img className={MBDStyle.thumbnail} src={pitch} alt="pitch"></img>
                        <h4 style={{letterSpacing: '0px', marginTop: '2rem'}}>Vertical</h4>
                        </ScrollAnimation>
                    </Grid>
                    <Grid item sm>
                        <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                        <img className={MBDStyle.thumbnail} src={yaw} alt="yaw"></img>
                        <h4 style={{letterSpacing: '0px', marginTop: '2rem'}}>Horizontal</h4>
                        </ScrollAnimation>
                    </Grid>
                    <Grid item sm>
                        <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                        <img className={MBDStyle.thumbnail} src={trigger} alt="trigger"></img>
                        <h4 style={{letterSpacing: '0px', marginTop: '2rem'}}>Trigger</h4>
                        </ScrollAnimation>
                    </Grid>
                    <Grid item sm>
                        <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
                        <img className={MBDStyle.thumbnail} src={multi} alt="multi"></img>
                        <h4 style={{letterSpacing: '0px', marginTop: '2rem'}}>Multiple</h4>
                        </ScrollAnimation>
                    </Grid>
                    </Grid>
                </div>
                </div>
            </div>

            <div className={MBDStyle.parallax2}>
                <div className={MBDStyle.benefitThreeWrapper}>
                <div className={MBDStyle.benefitThree} style={{color:'white'}}>
                    <ScrollAnimation animateIn='slideInLeft' animateOut='slideOutLeft' duration={0.5} offset={200}>
                    <h3 style={{fontSize: "30px", textTransform: "uppercase", fontWeight:700}}>Versatile and Powerful</h3>
                    <div className={MBDStyle.benefitText} style={{width: "40%", fontWeight: 300}}>
                        <p>
                        Because MO-Band is based on MIDI, it will not only work with your favourite DAW, but also your hardwares. You can use MO-Band to control your synth, drum machine, sequencer, looper, MFX... As long as the gear is compatiable with MIDI.
                        </p>
                        <Link to="s-motion"><button style={{width: 'auto', padding: '6px 16px 6px 16px', borderColor: "transparent"}}>About S-Motion Software</button></Link>
                    </div>
                    </ScrollAnimation>
                </div>
                </div>
            </div>

            <div className={MBDStyle.benefitOneWrapper} style={{minHeight:'0px', height: '200px',  backgroundColor:'rgb(235,235,235)', backgroundImage: 'none'}}>
                <div className={MBDStyle.benefitOne}>
                <h3 style={{fontSize: "30px", textTransform: "uppercase", fontWeight:700, marginBottom: '16px', marginTop: '32px'}}>Still have questions?</h3>
                <div className={MBDStyle.benefitText} style={{width: "40%", fontWeight: 300}}>
                    <button className={MBDStyle.contactBtn}><Link to='contact'>Contact Us</Link></button>
                </div>
                </div>
            </div>   
        </React.Fragment>       
    )
}

export default MOBandDescription
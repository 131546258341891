import React from 'react'
import IndexStyle from '../pages/index.module.css'
import muteIcon from '../assets/mute.png'
import unmuteIcon from '../assets/unmute.png'
import YouTube from 'react-youtube'
import {getIpCountry} from '../../Global_Config'

class MOBandTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {videoID:'S9pzzolpei4', player:null, muted: 1};
        this.toggleMute = this.toggleMute.bind(this);
        this.onReady = this.onReady.bind(this);
        this.onEnd = this.onEnd.bind(this);
        this.state = {
            player: null,
            userCountry: null,
            videoID: null,
            muted: true,
        }
    }

    async componentDidMount() {
        const country = await getIpCountry();
        this.setState({userCountry: country});
    }


    onReady(event) {
        this.setState({
            player: event.target,
        });
        this.state.player.setVolume(this.state.muted ? 0 : 100)
        // if (this.state.muted) {
        //     this.state.player.mute();
        // }
        
    }


    onEnd() {
        this.props.nextVideo();
    }

    toggleMute() {
        this.setState({muted: (this.state.muted == 0) ? 1 : 0});
        // console.log(player.contentWindow)
        if (this.state.player) {
            if (this.state.muted) {
                this.state.player.setVolume(100);
            } else {
                this.state.player.setVolume(0);
            }
        }
        
        
    }


    render() {
        const videos = this.state.userCountry === 'CN' ? 
            ['m3035emvhgz', 'd3035ruqce6', 'e30340ff4ao']
            :
            ['S9pzzolpei4', '_3tg_Es4KAc', 'T5x2L9pIgoM']
        if (this.state.videoID !== videos[this.props.value]) {
            this.setState({videoID: videos[this.props.value]})
        }

        return(
            <div>
                {this.state.userCountry === 'CN' ? null : <button onClick={this.toggleMute} className={IndexStyle.volumeButton}><img src={this.state.muted ? muteIcon:unmuteIcon} style={{width:'35px', margin: 'auto', paddingTop: '6px'}}/></button>}
                <div className={IndexStyle.respContainer}>
                {
                    this.state.userCountry === 'CN' ? 
                    <iframe className={IndexStyle.respIframe} allowFullScreen="true" frameborder="0" src={`https://v.qq.com/iframe/player.html?vid=${this.state.videoID}&auto=0`}></iframe>
                    : 
                    <YouTube className={IndexStyle.respIframe} videoId={this.state.videoID} onReady={this.onReady} onEnd={this.onEnd} opts={{playerVars: {autoplay: 1, rel: 0, loop: 0, playlist: this.state.videoID}}} /> 
                }
                </div>
                
            </div>
        )
    }
}


export default MOBandTab;
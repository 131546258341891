import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/seo';

import PreOrderStyles from './preorder.module.css';
import IndexStyle from './index.module.css';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MOBandTab from '../components/mobandTab';
import Axios from 'axios';
import Testimonial from '../components/Testimonial';
import Faq from '../components/faq';
import banner from '../assets/banner.jpg';
import thumbnail from '../assets/mb_thumbnail.jpg';
import { navigate } from "gatsby";
import { connect } from 'react-redux';
import { add_to_cart } from '../redux/cart.redux';
import Gallery from '../components/Gallery';

import MOBandDescription from '../components/mobandDescription';


class PreOrderPage extends React.Component {
  constructor() {
    super();
    this.state = {
      emailInput: "",
      value: 0,
      isMobile: true,
    }
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);

    this.handleEmailInput = this.handleEmailInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.nextVideo = this.nextVideo.bind(this);
    this.handleLearnMore = this.handleLearnMore.bind(this);
    this.handleTrackEvent = this.handleTrackEvent.bind(this);
    this.calWeek = this.calWeek.bind(this);
  }

  handleTrackEvent(evt) {
    if (typeof window !== 'undefined' && window.fbq) {
      window.fbq('trackCustom', evt);
    }
  }

  handleResize = e => {
    const windowSize = window.innerWidth;
    this.setState(state => ({...state, isMobile: windowSize < 768}))
  }

  componentDidMount() {
    const windowSize = window.innerWidth;
    this.setState(state => ({...state, isMobile: windowSize < 768}))
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize)
  }

  nextVideo() {
    if (this.state.value < 3) {
      this.setState(state => ({...state, value: this.state.value + 1}));
    } else {
      this.setState(state => ({...state, value: 0}));
    }
  }


  calWeek() {
    const a = new Date();
    const b = new Date('2019/8/31');
    const diff = Math.ceil((b-a)/604800000);
    return diff > 2 ? diff:2;
  }

  handleChange = (event, newValue) => {
    var evt = ""
    switch (newValue) {
      case 0:
        evt = "P_Quickstart"
        break
      case 1:
        evt = "P_Motion Mapping"
        break
      case 2:
        evt = "P_In Action"
        break
      default:
        break;
    }
    this.handleTrackEvent(evt)
    this.setState(state => ({...state, value: newValue}))
  }
  

  handleInputChange(event) {
    this.setState({ quantity: event.target.value });
  }


  handleEmailInput(event) {
    this.setState({emailInput: event.target.value});
  }

  handleSubmit(event) {
    // console.log(this.state.emailInput);
    
    if (window.fbq != null) {
      window.fbq('track', 'AddToWishlist');
    }

    let emailValidator = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    if (!emailValidator.test(this.state.emailInput)) {
      alert("Please enter a valid email")
      return
    }
    let config = {
      headers: {
        'Content-Type': 'application/json'
      }
    }
    let data = {email: this.state.emailInput}
    Axios.post('https://api.sonic-instruments.co/api/maillist', data, config).then(function(response) {
        // obj.displayAlert('Thanks for reaching out, we will get back to you shortly!')
        alert("Awesome! You have joined the waiting list.")
      })
      .catch(function(err) {
        // obj.displayAlert(`Oops, something went wrong... ${err}`)
        alert("Oops" + err)
      })
  }


  handleLearnMore() {
    if (window.fbq != null) {
      window.fbq('trackCustom', 'LearnMoreButton');
    }
  }


  handleButtonClick() {
    if (window.fbq != null) {
      window.fbq('trackCustom', 'MBOrderNowButton');
    }
    this.props.add_to_cart({
      name: "mo-band",
      list:["Fully customizable MIDI compatibility", "Compatible with both PC and Mac", "Latency as low as 1.5ms", "Hi-Res MIDI Support", "8-hour battery life"],
      oneliner:"Revolutionary wireless MIDI controller on your wrist",
      price: 299,
      quantity: 1,
      currency:"$",
      description:"Includes MO-Key (the USB Receiver), S-Motion and S-Board software(Mac, PC)",
      img: thumbnail,
    });
    navigate('/cart');
  }



  

  render() {
    const week = this.calWeek()


    const termsOutside = (
      <ul className={PreOrderStyles.includesList} style={{marginTop: '32px', marginBottom: '32px'}}>
        <li style={{fontWeight:700}}>30-Day Money Back Guarantee</li>
        {this.state.isMobile ? 
          <li>Free shipping in the US</li> :
          <li>Free shipping in the US, Est. Shipping time: {week} {week > 1 ? 'weeks' : 'week'}</li>
        }
        {this.state.isMobile ? 
          <li>Est. Shipping time: {week} {week > 1 ? 'weeks' : 'week'}</li>:
          null
        }
        
        <li>Any Questions? <a style={{textDecoration:'underline'}} href="mailto:info@sonichits.co?Subject=Question%20about%20MO-Band">Shoot us an email</a></li>
      </ul>
    )
    
    const infoBlock = (
      <div className={PreOrderStyles.imageContainer}>
              <div className={IndexStyle.tabs} style={{backgroundColor: 'rgba(0,0,0,0)', paddingTop: '32px'}}>
                <Tabs value={this.state.value} style={{marginLeft: '0px'}} variant="scrollable" scrollButtons="auto" onChange={this.handleChange}>
                  <Tab label="Quick Start" />
                  <Tab label="Motion Mapping" />
                  <Tab label="In Action" />
                </Tabs>
              </div>
              <div className={IndexStyle.containerTwoWrapper} style={{backgroundColor:'rgba(0,0,0,0)'}}>
                <div className={IndexStyle.containerTwo} style={{paddingBottom: '12px'}}>
                  <MOBandTab value={this.state.value} nextVideo={this.nextVideo}/>
                </div>
                <div className={PreOrderStyles.centeredDiv}>
                  {/* <Link to="/">
                    <button onClick={this.handleLearnMore} className={PreOrderStyles.learnMore}>Learn More</button>
                  </Link> */}
                </div>
                <div style={{marginTop: '32px', marginLeft: '15%'}}>
                      <ul className={PreOrderStyles.spec}> 
                        <li style={{fontSize: '14px', margin: '0px'}}>Fully customizable MIDI compatibility</li>
                        <li style={{fontSize: '14px', margin: '0px'}}>Compatible with both PC and Mac</li>
                        <li style={{fontSize: '14px', margin: '0px'}}>Latency down to 1.5ms</li>
                        <li style={{fontSize: '14px', margin: '0px'}}>Hi-Res MIDI Support</li>
                        <li style={{fontSize: '14px', margin: '0px'}}>8-hour battery life</li>
                      </ul>
                  </div>
              </div>
            </div>
    )

    
    return (
      <Layout location={this.props.location} title={'Preorder'} showAddToCartOn={this.handleButtonClick}>
        <SEO
          title={'MO-Band™ - Revolutionary wearable MIDI controller'}
          keywords={[`music`, `MIDI`, `audio engineering`, `MIDI controller`]}
          description={'MO-Band™ turns your hand gestures and movements into control signal, lets you morph your sound easily and expressively.'}
          image={thumbnail}
        />

        
        
        <div className={PreOrderStyles.container}>
            {!this.state.isMobile ? infoBlock : null}
            <div className={PreOrderStyles.contentContainer}>
              <Link to="/">
                <h1 className={PreOrderStyles.contentTitle}>MO-Band</h1>
              </Link>
                <p style={{fontWeight: 300, fontSize: this.state.isMobile ? '18px' : '22px'}}>Revolutionary wearable MIDI controller</p>
                  <img className={PreOrderStyles.thumbnail} src={thumbnail} style={{width: '160px', marginBottom:'0px', borderRadius: '50%'}} alt="hand-thumbnail"></img>
                <br/>
                <br/>
                <div className={PreOrderStyles.productPrice}>
                    <span className={PreOrderStyles.currPrice}>$299</span>
                    {/* <span className={PreOrderStyles.prevPrice}>$299.99</span> */}
                </div>                
                <div className={PreOrderStyles.buttonWrapper}>
                  <button onClick={this.handleButtonClick} className={PreOrderStyles.preOrderButton} disabled={true}>Currently unavailable</button>
                </div>
                
                {termsOutside}
                {this.state.isMobile ? infoBlock : null}
                <form>
                  <p className={PreOrderStyles.deliveryTime}>Join our email list to get a chance to win $50 off on MO-Band.</p>
                  <input type="text" name="email" placeholder="Email" className={PreOrderStyles.emailInput} onChange={this.handleEmailInput}/>
                  <input type="button" value="Submit" className={PreOrderStyles.submitBtn} onClick={this.handleSubmit}/>
                </form>
                <br/>
                {/* {paymentSuccess && <p>Thanks for purchasing!</p>} */}
            </div>
        </div>
        {/* {this.state.isMobile ? quoteMobile : quoteDesktop} */}
        <MOBandDescription />
        <div className={PreOrderStyles.testimonialContainer}>
          <Testimonial isMobile={this.state.isMobile}/>
        </div>
        {/* <div className={PreOrderStyles.galleryContainer}>
          <Gallery />
        </div> */}
        <div className={PreOrderStyles.faqContainer}>
          <Faq />
        </div>
      </Layout>
    )
  }
}

const actionCreators = { add_to_cart }

export default connect(null, actionCreators)(PreOrderPage)

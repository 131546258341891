import React, { Component } from "react"
import { Grid } from '@material-ui/core';
import colaps from '../assets/colaps.jpg';
import d_low from '../assets/dlow.jpg';
import bigman from '../assets/bigman.jpg'
import villain from '../assets/villain.jpg'
import napom from '../assets/napom.jpg'
import audical from '../assets/audical.jpg'
import amit from '../assets/amit.jpg'
import kento from '../assets/kento.jpg'
import TestimonialStyles from './Testimonial.module.css'

class TestimonialGrid extends Component {
    constructor(props) {
        super(props)
        this.state = {isShown: true};
        if (this.props.isMobile) {
            this.setState({isShown: true})
        }
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        if (this.props.isMobile) {
            this.setState({isShown: !this.state.isShown})
        }
    }
    
    render() {
        const {src, name, quote, url} = this.props;
        return (
            <div className={TestimonialStyles.container} onClick={this.handleClick}>
                <img src={src} alt={name} style={{width: '250px'}}/>
                {this.state.isShown ? 
                    <div className={TestimonialStyles.overlay}>
                        <a href={this.props.isMobile ? null:url} target="_blank">
                            <div className={TestimonialStyles.content}>
                                <p style={{width: '200px', lineHeight: '1.2'}}>
                                    {name}
                                </p>
                                <p style={{width: '200px', lineHeight: '1'}}>
                                    {quote}
                                </p>
                            </div>
                        </a>
                    </div>  
                : null}
                
            </div>
        )
    }
}

class Testimonial extends Component {
    render() {
        return (
            <div className={TestimonialStyles.gridContainer}>
                <h4 style={{letterSpacing: 1.5, fontSize: '32px'}}>Love From The Music Community</h4>
                <p style={{fontWeight: 300}}>
                    MO-Band is bringing in a new world of possibilities, see what the greatest beatboxers said about MO-Band.
                </p>
                <Grid container spacing={3}>
                    <Grid item xs={this.props.isMobile ? null:3}>
                        <TestimonialGrid src={colaps} 
                            name='@colapsbbx' 
                            quote={`This technology offers a whole new world of possibilities.`}
                            url='https://www.instagram.com/colapsbbx/'
                            isMobile={this.props.isMobile}
                        />
                    </Grid>
                    <Grid item xs={this.props.isMobile ? null:3}>
                        <TestimonialGrid src={bigman} 
                            name='@bigman_rtst' 
                            quote={`Wow! So cool!`}
                            url='https://www.instagram.com/bigman_rtst/'
                            isMobile={this.props.isMobile}
                        />
                    </Grid>
                    
                    <Grid item xs={this.props.isMobile ? null:3}>
                        <TestimonialGrid src={d_low} 
                            name='@dlowbeatboxblad' 
                            quote={`Wodnerful job, I seriously love it!`}
                            url='https://www.instagram.com/dlowbeatboxblad/'
                            isMobile={this.props.isMobile}
                        />
                    </Grid>

                    <Grid item xs={this.props.isMobile ? null:3}>
                        <TestimonialGrid src={napom} 
                            name='@napom_official' 
                            quote={`I'm excited to try it!`}
                            url='https://www.instagram.com/napom_official/'
                            isMobile={this.props.isMobile}
                        />
                    </Grid>

                    <Grid item xs={this.props.isMobile ? null:3}>
                        <TestimonialGrid src={villain} 
                            name='@villainbbx' 
                            quote={`This is definitely gonna be the future!`}
                            url='https://www.instagram.com/villainbbx/'
                            isMobile={this.props.isMobile}
                        />
                    </Grid>

                    <Grid item xs={this.props.isMobile ? null:3}>
                        <TestimonialGrid src={audical} 
                            name='@audicalmusic' 
                            quote={`I wanna be the first champion of MO-Band battle!`}
                            url='https://www.instagram.com/audicalmusic/'
                            isMobile={this.props.isMobile}
                        />
                    </Grid>

                    <Grid item xs={this.props.isMobile ? null:3}>
                        <TestimonialGrid src={amit} 
                            name='@amitbeats' 
                            quote={`Can't wait to try the MO-Band.`}
                            url='https://www.instagram.com/amitbeats/'
                            isMobile={this.props.isMobile}
                        />
                    </Grid>

                    <Grid item xs={this.props.isMobile ? null:3}>
                        <TestimonialGrid src={kento} 
                            name='@kento_yamamoto411' 
                            quote={`MO-Band will not only revolutionize beatbox, but it will also change how we perceive sound and music.`}
                            url='https://www.instagram.com/kento_yamamoto411/'
                            isMobile={this.props.isMobile}
                        />
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default Testimonial
import React, { Component } from 'react';
import List from '@material-ui/core/List';
import FaqCollapse from './faqCollaps'
import faqStyle from './faq.module.css'



class Faq extends Component {
    render() {
        return (
            <div className={faqStyle.container}>
                <h4 style={{letterSpacing: 1.5, fontSize: '32px', textAlign: 'center', paddingBottom: '32px'}}>FAQ</h4>
                <p style={{fontWeight: 300, textAlign: "center"}}>
                    Here are some of the most asked questions. If you still have questions or concerns, <a href="mailto:info@sonic-instruments.co?Subject=Question%20about%20MO-Band">shoot us an email</a> anytime and we'll get back to you in no time.
                </p>
                <List component="div" aria-labelledby="faq">
                    <FaqCollapse 
                        q="How does MO-Band work?" 
                        a="MO-Band comes with a USB receiver and a set of softwares. Plug the receiver to your computer, our software then translate your movements into digital control of music. It's that simple."
                    />
                    <FaqCollapse 
                        q="What's the latency? Is it responsive?" 
                        a="Our wireless protocol eliminates the latency down to 1.2ms when using a single MO-band, and under 5ms when using multiple MO-Bands. It's as responsive as a cable connection."
                    />
                    <FaqCollapse 
                        q="Can I use it on macOS? How about on Windows?" 
                        a="Absolutely! MO-Band is compatible with both Mac and PC."
                    />
                    <FaqCollapse 
                        q="Can I use Ableton Live with MO-Band? How about other DAWs" 
                        a="100%! In fact, you can get the most of MO-Band with Ableton Live with its MIDI Mapping functionality. And all the major DAWs are supported, such as Logic Pro, Cubase, Pro Tools, FL Studio, etc, as long as they support MIDI."
                    />
                    <FaqCollapse 
                        q="I saw you control a filter cutoff in your video, can I control other effects" 
                        a="Definitely! All the mappings are customizable. If you are using the Motion app, change target of the rings to different MIDI CC, just like using any physical MIDI controllers. If you are using MO-Rack, just simply change the source of a knob or a slider."
                    />
                    <FaqCollapse 
                        q="Can I adjust the sensitivity?" 
                        a="Sure thing! Open the Motion app and adjust the two little sliders on the rings."
                    />
                    <FaqCollapse 
                        q="Can I use MO-Band without a computer? How can I use it with my hardware effects?" 
                        a="You still needs a computer. To use it with your hardwares, buy a seperate USB-to-MIDI cable, then send the midi messages out from Motion App to your gears."
                    />
                    <FaqCollapse 
                        q="What’s the return policy?" 
                        a="We really sorry that MO-Band didn’t work out for you. We do have 30-day money back guarantee, just shoot us an email at info@sonic-instruments.co, we’ll take care of it. And if you have any problem, don’t hesitate to contact us anytime, we’ll get back to you ASAP."
                    />
                </List>
            </div>
        );
    }
}

export default Faq
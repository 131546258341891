import React, { Component } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import box0 from '../assets/box0.jpg'
import box1 from '../assets/box1.jpg'
import box2 from '../assets/box2.jpg'
import box3 from '../assets/box3.jpg'

class Gallery extends Component {
    render() {
        return (
            <Carousel infiniteLoop={true} autoPlay={true} interval={5000} showThumbs={false} showStatus={false}>
                <div>
                    <img src={box0} />
                </div>
                <div>
                    <img src={box1} />
                </div>
                <div>
                    <img src={box2} />
                </div>
                <div>
                    <img src={box3} />
                </div>
            </Carousel>
        );
    }
}

export default Gallery